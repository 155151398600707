/**
 * SELECT2.
 * multi/autocomplete (<input type='text' /> based) & single (<select /> based).
 */

.form-control.select2-container {
  border: none;
  /*height: 50px !important;*/
  margin: 0;
  padding: 0;
}

.form-control.select2-container {
  border: 1px #e4eaec solid;
  border-radius: 4px;
}

.select2-container-multi .select2-choices {
  padding: 0 6px;
}

.form-control .select2-choice,
.select2-container-multi.select2-container-active .select2-choices,
.select2-container-multi .select2-choices .select2-search-field input {
  height: 50px;
    -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
}

.form-control .select2-choice {
  background: none;
  border-color: #e4eaec;
  border: none;
  border-radius: 4px;
  padding: 12px 12px;
  line-height: 1.8em;
}

.select2-container-multi.select2-with-icon .select2-choices,
.form-control .select2-choice {
  padding-right: 48px; /* space for .select2-arrow */
}

.form-control.select2-container .select2-choices {
  border-radius: 4px;
  background: none;
}

.label.label-tags,
.select2-container-multi .select2-choices .select2-search-choice {
  background: #82858b;
  border: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  border-radius: 0;
    -webkit-box-shadow: none;
  box-shadow: none;
  color: #f6f7fd;
  font-size: 80%;
  filter: none;
  padding: 5px 6px;
  text-transform: uppercase;
}

.select2-container.select2-container-disabled {
  background-color: #fafafa;
}

.select2-container.select2-container-disabled .select2-choice,
.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background: none;
  border: none;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  display: none;
}

.select2-container.select2-container-disabled .select2-choice > .select2-chosen {
  color: #e3e4e5;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background-color: #82858b;
}

.select2-container-multi .select2-choices .select2-search-choice {
  margin-top: 12px;
}

.select2-container-multi .select2-arrow {
  display: inline-block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
}

.select2-container-multi .select2-search-choice-close,
.select2-container-multi .select2-search-choice-close:hover {
  background: none !important;
  background-size: 0;
  height: 15px;
  width: 15px;
}

.select2-container.select2-allowclear .select2-search-choice-close,
.select2-container.select2-allowclear .select2-search-choice-close:hover {
  background-size: 80px 52px !important;
  height: 15px;
  right: 62px;
  top: 18px;
  width: 15px;
}

.select2-container.select2-allowclear .select2-choice abbr:hover {
  background-position: right 0;
}

.form-control .select2-arrow,
.form-control .select2-choice .select2-arrow {
  background: transparent;
  border-left: 1px solid $border-color;
  border-width: 0;
  color: #7f7f87;
  line-height: 1.25em;
  padding: 12px 14px;
  width: 48px;
}

.form-control.select2-container.select2-dropdown-open,
.select2-container.select2-dropdown-open .select2-choice {
  border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  border-radius: 0;
}

.select2-container.select2-container-multi.select2-dropdown-open,
.select2-dropdown-open.select2-container-active .select2-choice,
.select2-dropdown-open.select2-container-active .select2-choices {
  border: 1px solid #5897fb;
}

.select-2-unique {
  width: 400px;
}

.select2-results .select2-highlighted {
  background-color: #f6f7fd;
  color: #333;
}

.select2-results .select2-result-label {
  padding: 10px;
}

.select-contact-list.form-control .select2-choice {
  height: 40px !important;
  padding: 7px 10px !important;
}

// Resets the style from .form-control which is applied via JS by select2 somehow.
.select2-result.form-control {
  border-width: 0;
  height: auto;
  padding: 0;
}

.select2-result.select2-results-dept-1 {
  padding-left: 15px;
}
